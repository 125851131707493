.premium-page {  
    background-color: #f5f5f5;  
    padding: 20px 0;  
}  
  
.premium-heading {  
    font-size: 2.5rem;  
    font-weight: bold;  
    color: #333;  
}  
  
.premium-description {  
    font-size: 1.2rem;  
    color: #666;  
    max-width: 800px;  
    margin: 0 auto;  
}  